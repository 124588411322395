import { defineStore } from 'pinia';

export const useScriptStore = defineStore('script', {
  state () {
    return {
      scripts: [
        {
          name: 'hubspot cookie optin',
          pre: '<!-- Start of HubSpot Embed Code --> ',
          script: {
            type: 'text/javascript',
            id: 'hs-script-loader',
            async: 'async',
            defer: 'defer',
            src: '//js.hs-scripts.com/2604014.js',
          },
          post: '<!-- End of HubSpot Embed Code -->',
        },
        {
          name: 'hubspot form script',
          script: {
            charSet: 'utf-8',
            type: 'text/javascript',
            src: '//js.hsforms.net/forms/v2.js',
          },
          code: "hbspt.forms.create({portalId: '2604014', formId: '35cd530f-826d-42c1-a3a3-846a3a5b207a'});"
        },
        {
          name: 'barchart announcement script',
          script: {
            type: "text/javascript" ,
            src: "https://nelsonirrigation.websol.barchart.com/main.php?domain=nelsonirrigation&page=snapshot&header=no"
          }
        }
      ]
    };
  },
  actions: {
    getScript(scriptStr) {
      let script = this.scripts.filter((script) => script.name === scriptStr);
      return script[0];
    }
  },
});
